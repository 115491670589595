import "./InventoryItemsPage.css";

import * as QueryMapper from "./InventoryItemQueryParamHelper";

import { GetInvItemStatus, InventoryItemStatusAsEnum, InventoryItemStatusEnum, InventoryItemStatusTranslationTerms } from "../../models/domain/inventory-items/InventoryItemStatusEnum";
import { formatNonIntegerNumber, formatRoundNumber } from "../../../../common/helpers/RoundNumber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { ReactComponent as ArrowDown } from "../../../../lib/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../lib/assets/icons/arrowUp.svg";
import { BetweenNumbersHelper } from "../../../../lib/helpers/BetweenNumbersHelper";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { ReactComponent as CleanIcon } from "../../../../lib/assets/icons/clean.svg";
import { ReactComponent as CloseIcon } from "../../../../lib/assets/icons/lock-btn.svg";
import { ReactComponent as CloseInventoryIcon } from "../../../../lib/assets/icons/close-icon.svg";
import { ColumnDefinition, IOrderInfo } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as CommentsIcon } from "../../../../lib/assets/icons/comments.svg";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ConsultInventoryDeviationPopup } from "../deviation/ConsultInventoryDeviationPopup";
import { ContextualMenu } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { CreateInventoryItemPopup } from "./popups/CreateInventoryItemPopup";
import { DateTime } from "luxon";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditInventoryDocumentItemIDPopup } from "./popups/EditInventoryDocumentItemIDPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ReactComponent as ExportIcon } from "../../../../lib/assets/icons/export.svg";
import { FormFieldSelectMultipleSimple } from "../../../../lib/components/form/form-field/FormFieldSelectMultipleSimple";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IInventoryStorageLocationStatsIntrinsicBehavior } from '../../components/InventoryStorageLocationStatsSection';
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { ReactComponent as ImportIcon } from "../../../../lib/assets/icons/import-dark.svg";
import { ImportLoader } from "../../../../lib/components/loader/ImportLoader";
import { ImportPopup } from "../../../../lib/components/popup/ImportPopup";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { InvalidInventoryItemsPopup } from "./popups/InvalidInventoryItemsPopup";
import { InventoriesService } from "../../services/InventoriesService";
import { InventoryDetails } from "../../models/domain/inventory/InventoryDetails";
import { InventoryItemState } from "../../models/dtos/InventoryItemDTOs/InventoryItemState";
import { InventoryItemSummary } from "../../models/domain/inventory-items/InventoryItemSummary";
import { InventoryItemsQueryOptions } from "../../models/dtos/InventoryItemDTOs/InventoryItemsQueryOptions";
import { InventoryItemsService } from "../../services/InventoryItemsService";
import { InventoryStatsSection } from "../../components/InventoryStatsSection";
import { InventoryStatusEnum } from "../../models/dtos/InventoryDTOs/InventoryStatusEnum";
import { InventoryStatusTranslationTerms } from "../../models/domain/inventory/InventoryStatusEnum";
import { ItemStateOption } from "../../models/domain/inventory-items/ItemState";
import { ReactComponent as MoreActionsIcon } from "../../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { RoundIconButton } from "../../../../lib/components/buttons/RoundIconButton";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { ReactComponent as ValidateIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { ValidateInvDocItemCountPopup } from "./popups/ValidateInvDocItemCountPopup";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getInventoryItemStatusCss } from "../../../../common/helpers/GetInventoryItemStatusCss";
import { getInventoryStatusCss } from "../../../../common/helpers/GetInventoryStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../../services/UseServiceCall";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";

import { ReactComponent as AtivateIcon } from "../../../../lib/assets/icons/check-btn.svg";

const inventorySvc = new InventoriesService();
const inventoryItemsSvc = new InventoryItemsService();


export const topPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCAL_ADMIN,
    Permission.FINANCES
];


export const managerPermission = [Permission.TEAM_MANAGER];


const defaultIventoryItemStateAsEnum = [
    InventoryItemStatusEnum.COUNTING
];



const labelInventoryItemStateSelector = (item: ItemStateOption) => item.description;
const idInventoryItemStateSelector = (item: ItemStateOption) => item.id;



interface ISetOptions {
    clearFilters?: boolean;
    defaultFilters?: boolean;
}



export interface FiltersForm {
    cleanFilters: () => void;
    setStatus: (status: InventoryItemStatusEnum[], options: ISetOptions | undefined) => void;
}



export interface ItemsFilters {
    itemStatus: ItemStateOption[];
}




export function InventoryItemsPage() {

    const inventoryItemsStatesOptions = useMemo(() => {
        return GetInvItemStatus();
    }, [GetInvItemStatus]);


    const defaultInventoryItemStates = useMemo(() => {
        if (!defaultIventoryItemStateAsEnum && !inventoryItemsStatesOptions) return;
        const inventoryItemStatesKeysAsString = defaultIventoryItemStateAsEnum.map((key) => key.toString());
        return inventoryItemsStatesOptions.filter((obj) => inventoryItemStatesKeysAsString.includes(obj.id));
    }, [defaultIventoryItemStateAsEnum, inventoryItemsStatesOptions]);


    const [searchParams] = useSearchParams();


    const locationID = useGlobalLocation();
    const createNotification = useCreateNotification();
    const navigate = useNavigate();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const windowResize = useWindowResize();
    const upperRowRef = useRef<HTMLDivElement>(null);
    const bottomRowRef = useRef<HTMLDivElement>(null);
    const { id: inventoryID } = useParams();

    const statsSectionRef = useRef<IInventoryStorageLocationStatsIntrinsicBehavior>(null);

    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const { isLoadingPermissions: isLoadingManagerPermission, hasPermission: hasManagerPermission } = useHasPermissions(managerPermission);

    const [inventoryDetails, setInventoryDetails] = useState<InventoryDetails>();
    const [inventoryItems, setInventoryItems] = useState<InventoryItemSummary[]>();
    const [totalItems, setTotalItems] = useState<number>(0);
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const [isFiltersPanelExpanded, setIsFiltersPanelExpanded] = useState<boolean>(false);
    const [isFiltersWrapped, setIsFiltersWrapped] = useState<boolean>(false);

    const [timer, setTimer] = useState<NodeJS.Timeout>();


    /****************************
    *   service calls     
    *****************************/
    const getInventoryByIDCall = useServiceCallPro(inventorySvc.getInventoryByID);
    const putInventoryStateCall = useServiceCallPro(inventorySvc.updateInventoryState);
    const [getInventoryItemsCall, inventoryItemsCallIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.getInventoryItems);
    const [validateItem, validateItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.updateInventoryItemState);
    const [closeItem, closeItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.closeInventoryItem);
    const [getDownloadReportUrl] = useServiceCallPro2(inventorySvc, inventorySvc.downloadUrlForInventoryExportReport)
    const [getDownloadAccessesUrl] = useServiceCallPro2(inventorySvc, inventorySvc.downloadUrlForInventoryAccesses)
    const uploadFileCall = useServiceCallPro(inventoryItemsSvc.uploadInventoryItemsFromXLSX);
    const [editInventoryItemsByFileCall, editInventoryItemsByFileCallIsLoading] = useServiceCallPro2(inventorySvc, inventorySvc.editInventoryItemsFromXLSX);


    /****************************
          FILTER STATES
    *****************************/

    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(searchParams.get("pageSize") || "10"));
    const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get("page") || "0"));

    const materialNumberFilter = useFormControl<string>({ initialValue: searchParams.get("materialNumber") ?? undefined });
    const materialDescriptionFilter = useFormControl<string>({ initialValue: searchParams.get("materialDescription") ?? undefined });
    const batchFilter = useFormControl<string>({ initialValue: searchParams.get("batch") ?? undefined });
    const storageFilter = useFormControl<string>({ initialValue: searchParams.get("storage") ?? undefined });
    const inventoryItemStatesFilter = useFormControl<ItemStateOption[]>({ initialValue: QueryMapper.mapToItemStateOption(searchParams.getAll("idItemStatus")) });

    const [isNewItemsSelected, setIsNewItemsSelected] = useState<boolean>(searchParams.get("newItemsSelected") !== null ? true : false);

    const [orderColumn, setOrderColumn] = useState<IOrderInfo>({
        columnKey: searchParams.get("orderColumn") || "MaterialNumber",
        direction: (searchParams.get("orderDirection") === "ASC" || searchParams.get("orderDirection") === "DESC")
            ? searchParams.get("orderDirection") as "ASC" | "DESC"
            : "ASC"
    });




    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getInventoryDetails();
    }, []);


    useEffect(() => {

        const controller = new AbortController();
        getInventoryItems(controller);
        return () => { controller.abort() };

    }, [itemsPerPage, currentPage, orderColumn]);




    useEffect(() => {

        if (timer)
            clearTimeout(timer);

        var responseTimeOut = setTimeout(() => {

            if (timer && currentPage === 0) {
                const controller = new AbortController();
                getInventoryItems(controller);
                return () => { controller.abort() };
            }
            else if (timer)
                setCurrentPage(0);

        }, 500);

        setTimer(responseTimeOut);
    }, [materialDescriptionFilter.value, materialNumberFilter.value, batchFilter.value, storageFilter.value, inventoryItemStatesFilter.value, isNewItemsSelected]);





    /****************************
     * DATE REQUESTS
     *****************************/

    const getInventoryItems = useCallback((controller: AbortController | undefined) => {

        if (!locationID || !inventoryID) return;

        var request: InventoryItemsQueryOptions = {
            page: currentPage,
            pageLength: itemsPerPage,
            materialNumber: materialNumberFilter.value,
            materialDescription: materialDescriptionFilter.value,
            batch: batchFilter.value,
            storage: storageFilter.value,
            newItems: isNewItemsSelected ? isNewItemsSelected : undefined,
            state: inventoryItemStatesFilter.value?.map((obj) => InventoryItemStatusAsEnum[parseInt(obj.id)]),
            column: orderColumn.columnKey,
            isDescending: orderColumn.direction === "DESC"
        };

        const url = new URL(window.location.origin + window.location.pathname);
        request.page && url.searchParams.append('page', `${request.page}`);
        request.pageLength && url.searchParams.append('pageSize', `${request.pageLength}`);
        request.materialNumber && url.searchParams.append('materialNumber', `${request.materialNumber}`);
        request.materialDescription && url.searchParams.append('materialDescription', `${request.materialDescription}`);
        request.batch && url.searchParams.append('batch', `${request.batch}`);
        request.storage && url.searchParams.append('storage', `${request.storage}`);
        request.newItems && url.searchParams.append('newItemsSelected', `${request.newItems}`);
        request.state?.forEach(status => { request.state && url.searchParams.append('idItemStatus', `${status}`); });
        request.column && url.searchParams.append('orderColumn', orderColumn.columnKey);
        if (request.isDescending !== undefined) {
            url.searchParams.append('orderDirection', orderColumn.direction);
        }

        window.history.pushState(null, '', url.toString());


        getInventoryItemsCall(`${locationID}`, inventoryID, controller?.signal ? controller.signal : undefined, request)
            .then((data) => {
                setInventoryItems(data.items);
                setTotalItems(data.totalItems)

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, setInventoryItems, setTotalItems, openPopup, getInventoryItemsCall,
        currentPage, itemsPerPage, materialNumberFilter.value, materialDescriptionFilter.value, batchFilter.value,
        storageFilter.value, isNewItemsSelected, inventoryItemStatesFilter.value, orderColumn
    ]);




    const getInventoryDetails = useCallback(() => {

        if (!locationID || !inventoryID) return;

        getInventoryByIDCall.invoke(`${locationID}`, inventoryID)
            .then((data) => {
                setInventoryDetails(data || undefined);

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, setInventoryDetails, getInventoryByIDCall.invoke, openPopup]);




    const handleFileImport = useCallback((file: File) => {
        if (!locationID || !inventoryID) return;

        setIsLoadingImport(true);
        uploadFileCall.invoke(`${locationID}`, inventoryID, file).then(res => {
            setIsLoadingImport(false);
            getInventoryItems(undefined);
            getInventoryDetails();
            statsSectionRef?.current?.refresh();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.ITEM.ImportItemsSuccess")}
                />
            );
            if (res.invalidItemsCount > 0)
                openPopup(<InvalidInventoryItemsPopup>{translate("INVENTORY.POPUP.InvalidItemsCount", res.invalidItemsCount.toString())}</InvalidInventoryItemsPopup>)
        }).catch(error => {
            setIsLoadingImport(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, createNotification, uploadFileCall.invoke, openPopup, inventoryID, getInventoryItems, getInventoryDetails]);




    const handleEditItemsFileImport = useCallback((file: File) => {
        if (!locationID || !inventoryID) return;

        editInventoryItemsByFileCall(`${locationID}`, inventoryID, file).then(res => {
            getInventoryItems(undefined);
            getInventoryDetails();
            statsSectionRef?.current?.refresh();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.ITEM.ImportItemsSuccess")}
                />
            );
        }).catch(error => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, createNotification, editInventoryItemsByFileCall, openPopup, inventoryID, getInventoryItems, getInventoryDetails]);



    const closeInventory = useCallback(() => {

        if (!locationID || !inventoryID) return;

        putInventoryStateCall.invoke(`${locationID}`, inventoryID, { state: InventoryStatusEnum.CLOSED })
            .then((_) => {
                getInventoryDetails();
                getInventoryItems(undefined);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, inventoryID, getInventoryDetails, getInventoryItems, openPopup, putInventoryStateCall.invoke]);



    const activateInventory = useCallback(() => {

        if (!locationID || !inventoryID) return;

        putInventoryStateCall.invoke(`${locationID}`, inventoryID, { state: InventoryStatusEnum.ONGOING })
            .then((_) => {
                getInventoryDetails();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEM.ImportItemsSuccess")}
                    />
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, inventoryID, createNotification, getInventoryDetails, openPopup, putInventoryStateCall.invoke]);


    /****************************
     * USER ACTIONS
     ***************************/

    const onPageAndItemsChanged = useCallback((pageLength: number, currentPage: number) => {
        setCurrentPage(currentPage);
        setItemsPerPage(pageLength)
    }, [setCurrentPage, setItemsPerPage]);



    const handleClearFiltersButtonClicked = useCallback(() => {
        setCurrentPage(0);
        materialNumberFilter.setValue("");
        materialDescriptionFilter.setValue("");
        batchFilter.setValue("");
        storageFilter.setValue("");
        setIsNewItemsSelected(false);
        inventoryItemStatesFilter.setValue([]);
    }, [storageFilter.setValue, materialDescriptionFilter.setValue, setCurrentPage,
    materialNumberFilter.setValue, batchFilter.setValue, setIsNewItemsSelected, inventoryItemStatesFilter.setValue, defaultInventoryItemStates]);



    const handleOnValidateItemClicked = useCallback((itemId: string) => {
        if (!locationID || !inventoryID || !itemId) return;

        validateItem(`${locationID}`, inventoryID, itemId, { state: InventoryItemState.VALIDATED }).then(_ => {
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate(
                        "INVENTORY.ITEM.MESSAGES.ValidateItemSuccessMessage"
                    )}
                ></Notification>
            );

            getInventoryItems(undefined);
            statsSectionRef?.current?.refresh();
        }).catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, inventoryID, validateItem, getInventoryItems, openPopup, createNotification, statsSectionRef]);



    const handleOnCloseItemClicked = useCallback((itemId: string) => {
        if (!locationID || !inventoryID || !itemId) return;

        closeItem(`${locationID}`, inventoryID, itemId).then(_ => {
            getInventoryItems(undefined);
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate(
                        "INVENTORY.ITEM.MESSAGES.CloseItemSuccessMessage"
                    )}
                />
            );
            statsSectionRef?.current?.refresh();
        }).catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, inventoryID, createNotification, getInventoryItems, openPopup, closeItem, statsSectionRef]);


    const handleOnExportButtonClicked = useCallback(() => {
        if (!locationID || !inventoryID) return;
        getDownloadReportUrl(`${locationID}`, inventoryID).then(url => window.open(url, "_blank"));
    }, [getDownloadReportUrl, inventoryID, locationID]);


    const handleOnExportAccessesButtonClicked = useCallback(() => {
        if (!locationID || !inventoryID) return;
        getDownloadAccessesUrl(`${locationID}`, inventoryID).then(url => window.open(url, "_blank"));
    }, [getDownloadAccessesUrl, inventoryID, locationID]);


    /****************************
     * CSS & HTML
     *****************************/

    const renderInvItemActionBtn = useCallback((item: InventoryItemSummary) => {
        if (inventoryDetails?.status === InventoryStatusEnum.CANCELED || inventoryDetails?.status === InventoryStatusEnum.CLOSED) return;
        switch (item.status) {
            case InventoryItemStatusEnum.READY_TO_BE_VALIDATED:
                if (!item.permissions?.canValidate) return;
                return <Tooltip
                    text={translate("INVENTORY.ITEM.ValidateCounts")}>
                    <RoundIconButton icon={<ValidateIcon />}
                        onClick={() => {
                            if (!item.id || !locationID || !inventoryID) return;
                            openPopup(
                                <WarningPopup
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => handleOnValidateItemClicked("" + item.id)}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("INVENTORY.ITEM.Validate")}
                                >
                                    {translate("INVENTORY.ITEM.MESSAGES.ValidateItemConfirmText")}
                                </WarningPopup>
                            )
                        }} />
                </Tooltip >;
            case InventoryItemStatusEnum.COUNTING:
                if (!item.permissions?.canValidate) return;
                return <Tooltip
                    text={translate("INVENTORY.ITEM.ValidateCounts")}>
                    <RoundIconButton icon={<ValidateIcon />}
                        onClick={() => {
                            if (!item.id || !locationID || !inventoryID) return;

                            if (item.isProblematic || item.quantityCounted === 0)
                                openPopup(<ValidateInvDocItemCountPopup
                                    locationID={"" + locationID}
                                    inventoryID={"" + inventoryID}
                                    inventoryItemID={"" + item.id}
                                    hasZeroQuantity={item.quantityCounted === 0}
                                    onCompletedOperations={() => {
                                        statsSectionRef?.current?.refresh();
                                        getInventoryItems(undefined);
                                    }} />)
                            else
                                openPopup(
                                    <WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => handleOnValidateItemClicked("" + item.id)}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("INVENTORY.ITEM.Validate")}
                                    >
                                        {translate("INVENTORY.ITEM.MESSAGES.ValidateItemConfirmText")}
                                    </WarningPopup>
                                )
                        }} />
                </Tooltip >;
            case InventoryItemStatusEnum.VALIDATED:
                if (!item.permissions?.canClose) return;
                return <Tooltip text={translate("INVENTORY.ITEM.CloseItem")}>
                    <RoundIconButton icon={<CloseIcon />}
                        onClick={() =>
                            openPopup(
                                <WarningPopup
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => handleOnCloseItemClicked("" + item.id)}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("INVENTORY.ITEM.Close")}
                                >
                                    {translate("INVENTORY.ITEM.MESSAGES.CloseItemConfirmText")}
                                </WarningPopup>
                            )} />
                </Tooltip>;
            default:
                return null;
        }
    }, [handleOnValidateItemClicked, handleOnCloseItemClicked, openPopup, inventoryDetails?.status,
        closePopup, getInventoryItems, inventoryID, locationID, statsSectionRef]);




    const columns: ColumnDefinition<InventoryItemSummary>[] = useMemo(() => [
        {
            cellRenderProp: (v) => windowResize > 1200 ? v.materialNumber : <div className="info-box">
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Material") + ": "}<span>{v.materialNumber}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Description") + ": "}<span>{v.materialDescription}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Batch") + ": "}<span>{v.batch}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Storage") + ": "}<span>{v.storage}<ConditionalRender if={!!v.bin}><small>/{v.bin}</small></ConditionalRender></span></div>
            </div>,
            headerRender: "Info",
            width: "40%",
            isSortable: true,
            isVisible: BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
        },
        {
            cellRenderProp: (v) => v.materialNumber,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Material"),
            width: "15%",
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
            columnKey: "MaterialNumber",
            isSortable: true,
            isMobilePrimaryCell: true
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.materialDescription,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Description"),
            width: "18%",
            isSortable: true,
            columnKey: "MaterialDescription",
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.batch,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Batch"),
            isSortable: true,
            columnKey: "Batch",
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => <>{v.storage}<ConditionalRender if={!!v.bin}><small>/{v.bin}</small></ConditionalRender></>,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Storage"),
            isSortable: true,
            columnKey: "Storage",
            width: "8%",
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
        },
        {
            cellRenderProp: (v: InventoryItemSummary) =>/*  v.specialSAP || v.typeSAP ? */
                <div className="special-attributes">
                    <div className="special-sap">
                        <div className="label">Special:</div>
                        <div className="value"> {v.specialSAP ? v.specialSAP : "-"} </div>
                    </div>
                    <div className="sap-type">
                        <div className="label"> {translate("INVENTORY.ITEM.INFOANDFORM.SapTypeId") + ":"} </div>
                        <div className="value"> {v.typeSAP ? v.typeSAP : "-"} </div>
                    </div>
                </div> /* : null */,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Attributes"),
            width: "10%"
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.quantitySAP === 0 ? "0" : formatNonIntegerNumber(v.quantitySAP, 3),
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.SAPQuantity"),
            isSortable: true,
            columnKey: "QuantitySAP",
            width: "8%"
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.quantityCounted === 0 ? "0" : formatNonIntegerNumber(v.quantityCounted, 3),
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.CountedQuantity"),
            width: "10%"
        },

        {
            cellRenderProp: (v: InventoryItemSummary) => v.units,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Unit"),
            width: "5%"
        },
        {
            cellRenderProp: (v) =>
                <div className="deviation">
                    <div className="qty-difference-block">
                        <div className="label">{translate("INVENTORY.DEVIATION.INFOANDFORM.Quantity") + ":"}</div>
                        <div className={v.isProblematic ? "value-red" : undefined}>
                            {formatNonIntegerNumber(v.quantityDeviationPercentage, 3) + "%" ?? "-"}
                        </div>
                    </div>
                    <div className="monetary-block">
                        <div className="label">{translate("INVENTORY.DEVIATION.INFOANDFORM.Monetary") + ":"}</div>
                        <div className={v.isProblematic ? "value-red" : undefined}>
                            {formatRoundNumber(v.monetaryDeviationValue, 2) + "€" ?? "-"}
                        </div>
                    </div>
                </div>,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Deviation"),
            width: "10%",
            isSortable: true,
            columnKey: "MonetaryDeviationValue",
            isMobilePrimaryCell: true
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => (<Tag text={translate(InventoryItemStatusTranslationTerms[v.status])} backgroundColor={getInventoryItemStatusCss(v.status)} isTiny={windowResize < 1200} />),
            headerRender: translate("INVENTORY.Status"),
            width: "10%",
            isStatusCell: true
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.hasComments ? <Tooltip text={translate("INVENTORY.ITEM.ItemWithComments")}><CommentsIcon /></Tooltip> : null,
            width: "24px",
            isMobileHeaderIcon: true
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => renderInvItemActionBtn(v),
            width: "24px",
            isMobileHeaderIcon: true
        },
        {
            cellRenderProp: (v) => {
                if (getInventoryByIDCall.isLoading || inventoryDetails?.status === InventoryStatusEnum.CLOSED || inventoryDetails?.status === InventoryStatusEnum.CANCELED || !v.isNew) return null;
                if (isLoadingPermissions || !hasPermission) return null;
                return (<ContextualMenu
                    items={[
                        {
                            label: translate("INVENTORY.POPUP.EditDocumentSapId"),
                            icon: <EditIcon />,
                            navigateTo: "",
                            onClick: () => {
                                if (!v.id || !locationID || !inventoryID) return;
                                openPopup(
                                    <EditInventoryDocumentItemIDPopup
                                        locationID={"" + locationID}
                                        inventoryID={"" + inventoryID}
                                        inventoryItemID={"" + v.id}
                                        onCompletedOperations={() => getInventoryItems(undefined)}
                                    />
                                );
                            }
                        },
                    ]}
                >
                    <ScalableIconButton size={24} icon={<MoreActionsIcon />} />
                </ContextualMenu>)
            },
            columnKey: "moreActions",
            width: "24px",
            isMobileHeaderIcon: true
        },
    ], [locationID, inventoryID, getInventoryItems,
        renderInvItemActionBtn, openPopup, windowResize, hasPermission, isLoadingPermissions,
        getInventoryByIDCall.isLoading, inventoryDetails]);


    const renderStorageDetailsHeader = useMemo(() => {
        return (<>
            <div className="title">{translate("INVENTORY.InventoryData")}</div>
            {inventoryDetails?.status ?
                <Tag
                    text={inventoryDetails?.status ? translate(InventoryStatusTranslationTerms[inventoryDetails?.status]) : ""}
                    backgroundColor={inventoryDetails?.status ? getInventoryStatusCss(inventoryDetails?.status) : undefined}
                    isTiny={windowResize < 1024}
                /> : null}
        </>);
    }, [inventoryDetails?.status, windowResize]);






    const renderCardContainerInfo = useMemo(() => {
        return <FormSection unsetFlexGrow>
            <InfoBlock
                label={translate("INVENTORY.Name")}
                value={inventoryDetails?.name ?? ""}
            />
            <InfoBlock
                label={translate("INVENTORY.InitialDate")}
                value={inventoryDetails?.startDate ? DateTime.fromISO(inventoryDetails?.startDate).toFormat("dd/MM/yyyy") : ""}
            />
            <InfoBlock
                label={translate("INVENTORY.FinalDate")}
                value={inventoryDetails?.endDate ? DateTime.fromISO(inventoryDetails?.endDate).toFormat("dd/MM/yyyy") : ""}
            />
        </FormSection>
    }, [inventoryDetails?.name, inventoryDetails?.startDate, inventoryDetails?.endDate]);




    useEffect(() => {
        const upperRowNode = upperRowRef.current;
        const bottomRowNode = bottomRowRef.current;

        if (upperRowNode && bottomRowNode) {

            let hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
            });


            while (hasWrapOccurred) {
                const lastChild = upperRowNode.lastElementChild;
                setIsFiltersWrapped(true);
                if (lastChild) {
                    upperRowNode.removeChild(lastChild);
                    bottomRowNode.prepend(lastChild);
                }
                hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                    return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
                });
            }
        }
    }, [windowResize]);


    const renderFilters = useMemo(() => {
        return <div className="filters">
            <div className="upper-row-filters" ref={upperRowRef}>
                <FormFieldTextInput
                    formControl={materialNumberFilter}
                    placeholder={translate("INVENTORY.Material")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldTextInput
                    formControl={materialDescriptionFilter}
                    placeholder={translate("INVENTORY.Description")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldTextInput
                    formControl={batchFilter}
                    placeholder={translate("INVENTORY.Batch")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldTextInput
                    formControl={storageFilter}
                    placeholder={translate("INVENTORY.Storage")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldSelectMultipleSimple<ItemStateOption>
                    placeholder={translate("COMMON.FORM.SelectStatusPlaceholder")}
                    options={inventoryItemsStatesOptions.filter((a) => a.id !== "0")}
                    labelSelector={labelInventoryItemStateSelector}
                    idSelector={idInventoryItemStateSelector}
                    formControl={inventoryItemStatesFilter}
                    maxHeightOptions={"16rem"}
                />
                <Checkbox
                    text={translate("INVENTORY.ITEM.NewItems")}
                    isChecked={isNewItemsSelected !== undefined && isNewItemsSelected}
                    onClick={() => { setIsNewItemsSelected(!isNewItemsSelected); }}
                />
            </div>
            <div className="action-button-filters">
                {isFiltersWrapped ? <IconButton
                    icon={isFiltersPanelExpanded ? <ArrowUp /> : <ArrowDown />}
                    type="tertiary"
                    onClick={() => setIsFiltersPanelExpanded(!isFiltersPanelExpanded)}
                    className="view-more-filters-btn"
                /> : null}
                <Tooltip text={translate("COMMON.FORM.CleanFilters")}>
                    <IconButton
                        icon={<CleanIcon />}
                        type="secondary"
                        onClick={handleClearFiltersButtonClicked}
                        className="reset-filters-btn"
                    />
                </Tooltip>
            </div>
            <div className={"bottom-row-filters" + (isFiltersPanelExpanded ? " expanded" : "")} ref={bottomRowRef}>
            </div>
        </div>
    }, [materialNumberFilter, isNewItemsSelected,
        setIsNewItemsSelected, handleClearFiltersButtonClicked,
        batchFilter, materialDescriptionFilter, storageFilter, isFiltersPanelExpanded, isFiltersWrapped]);




    const handleClickImportBtn = useCallback(() => {
        openPopup(<ImportPopup
            customizedImportMessage={translate("COMMON.POPUPS.ImportSapStockText")}
            onClickDownloadTemplateBtn={() =>

                window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Inventory_Items_Template.xlsx", "_blank")}

            onClickImportBtn={handleFileImport} />);
    }, [handleFileImport, openPopup]);



    const handleClickUpdateItemsBtn = useCallback(() => {
        openPopup(<ImportPopup
            hideTemplateContainer
            customizedImportMessage={translate("COMMON.POPUPS.UpdateItemsText")}
            onClickDownloadTemplateBtn={() => window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Inventory_Items_Template.xlsx", "_blank")}
            onClickImportBtn={handleEditItemsFileImport} />)
    }, [handleEditItemsFileImport, openPopup]);



    const handleClickCreateItemBtn = useCallback(() => {
        if (!inventoryID) return;
        openPopup(<CreateInventoryItemPopup
            onCompletedOperations={() => getInventoryItems(undefined)}
            locationID={`${locationID}`}
            inventoryID={inventoryID}
            inventoryName={inventoryDetails?.name ? inventoryDetails?.name : ""} />)
    }, [getInventoryItems, inventoryDetails?.name, inventoryID, locationID, openPopup]);

    const handleClickCloseInventoryBtn = useCallback(() => {
        openPopup(
            <WarningPopup
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={closeInventory}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("INVENTORY.CloseInventory")}
            >
                {translate("INVENTORY.POPUP.CloseInventoryConfirmMessage", inventoryDetails?.name || "")}
            </WarningPopup>)
    }, [inventoryDetails?.name, openPopup, closeInventory, closePopup]);


    const handleClickActivateInventoryBtn = useCallback(() => {
        openPopup(
            <WarningPopup
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={activateInventory}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("INVENTORY.AtivateInventory")}
            >
                {translate("INVENTORY.POPUP.AtivateInventoryConfirmMessage", inventoryDetails?.name || "")}
            </WarningPopup>)
    }, [inventoryDetails?.name, openPopup, activateInventory, closePopup]);


    const renderActionButtons = useMemo(() => {
        return windowResize > 768 ?

            <>

                <ContextualMenu
                    items={[
                        {
                            label: translate("INVENTORY.DEVIATION.ViewDeviation"),
                            icon: <EditIcon />,
                            navigateTo: "",
                            onClick: () => {
                                if (!locationID || !inventoryID) return;
                                openPopup(<ConsultInventoryDeviationPopup
                                    inventoryID={"" + inventoryID}
                                    locationID={"" + locationID}
                                    inventoryName={inventoryDetails?.name}
                                    onCompletedOperations={() => { }} />)
                            }
                        },
                        {
                            label: translate("INVENTORY.STORAGE.CreateItem"),
                            icon: <AddIcon />,
                            navigateTo: "",
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED,
                            onClick: handleClickCreateItemBtn
                        },
                        {
                            label: translate("INVENTORY.ExportAccesses"),
                            icon: <ExportIcon />,
                            navigateTo: "",
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && (inventoryDetails?.status === InventoryStatusEnum.REGISTERED || inventoryDetails?.status === InventoryStatusEnum.ONGOING),
                            onClick: handleOnExportAccessesButtonClicked,
                        },
                        {
                            label: translate("INVENTORY.CloseInventory"),
                            icon: <CloseInventoryIcon />,
                            navigateTo: "",
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED,
                            onClick: handleClickCloseInventoryBtn
                        },
                    ]}
                >
                    <ScalableIconButton size={40} icon={<MoreActionsIcon />} />
                </ContextualMenu>



                {!isLoadingPermissions && hasPermission ?
                    <>
                        {!getInventoryByIDCall.isLoading && inventoryDetails?.status === InventoryStatusEnum.ONGOING ?
                            <Button
                                text={translate("INVENTORY.ITEM.UpdateItems")}
                                type="secondary"
                                onClick={handleClickUpdateItemsBtn}
                                className="import-storages"
                            /> : null
                        }
                        {!getInventoryByIDCall.isLoading && inventoryDetails?.status === InventoryStatusEnum.REGISTERED ?
                            <Button
                                text={translate("INVENTORY.ITEM.ImportItems")}
                                type="secondary"
                                onClick={handleClickImportBtn}
                                className="import-storages"
                            /> : null}

                        {!getInventoryByIDCall.isLoading && inventoryDetails?.status === InventoryStatusEnum.REGISTERED && totalItems > 0 ?
                            <Button
                                text={translate("INVENTORY.AtivateInventory")}
                                type="primary"
                                onClick={handleClickActivateInventoryBtn}
                                className="ativate-inventory"
                            /> : null}
                        {!getInventoryByIDCall.isLoading && inventoryDetails?.status !== InventoryStatusEnum.REGISTERED ?
                            <Button
                                text={translate("INVENTORY.Export")}
                                type="secondary"
                                onClick={handleOnExportButtonClicked}
                            /> : null}
                    </> : null}

                {!isLoadingManagerPermission && hasManagerPermission && (inventoryDetails?.status === InventoryStatusEnum.REGISTERED || inventoryDetails?.status === InventoryStatusEnum.ONGOING) ?
                    <Button text={translate("INVENTORY.ExportAccesses")}
                        type="secondary"
                        onClick={handleOnExportAccessesButtonClicked} />
                    : null}
            </> :
            <>

                <ContextualMenu
                    items={[
                        {
                            label: translate("INVENTORY.AtivateInventory"),
                            icon: <></>,
                            navigateTo: "",
                            isVisible: !getInventoryByIDCall.isLoading && inventoryDetails?.status === InventoryStatusEnum.REGISTERED && totalItems > 0,
                            onClick: handleClickActivateInventoryBtn,
                        },
                        {
                            label: translate("INVENTORY.ITEM.UpdateItems"),
                            icon: <ImportIcon />,
                            navigateTo: "",

                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && inventoryDetails?.status === InventoryStatusEnum.ONGOING,
                            onClick: handleClickUpdateItemsBtn,
                        },
                        {
                            label: translate("INVENTORY.ITEM.ImportItems"),
                            icon: <ImportIcon />,
                            navigateTo: "",
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && inventoryDetails?.status === InventoryStatusEnum.REGISTERED,
                            onClick: handleClickImportBtn,
                        },
                        {
                            label: translate("INVENTORY.Export"),
                            icon: <ExportIcon />,
                            navigateTo: "",
                            onClick: handleOnExportButtonClicked,
                            isVisible: !isLoadingPermissions && hasPermission && inventoryDetails?.status !== InventoryStatusEnum.REGISTERED
                        },

                        {
                            label: translate("INVENTORY.DEVIATION.ViewDeviation"),
                            icon: <EditIcon />,
                            navigateTo: "",
                            onClick: () => {
                                if (!locationID || !inventoryID) return;
                                openPopup(<ConsultInventoryDeviationPopup
                                    inventoryID={"" + inventoryID}
                                    locationID={"" + locationID}
                                    inventoryName={inventoryDetails?.name}
                                    onCompletedOperations={() => { }} />)
                            }
                        },
                        {
                            label: translate("INVENTORY.STORAGE.CreateItem"),
                            icon: <AddIcon />,
                            navigateTo: "",
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED,
                            onClick: handleClickCreateItemBtn
                        },
                        {
                            label: translate("INVENTORY.ExportAccesses"),
                            icon: <ExportIcon />,
                            navigateTo: "",
                            isVisible: !getInventoryByIDCall.isLoading && inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED,
                            onClick: handleOnExportAccessesButtonClicked,
                        },
                        {
                            label: translate("INVENTORY.CloseInventory"),
                            icon: <CloseInventoryIcon />,
                            navigateTo: "",
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED,
                            onClick: handleClickCloseInventoryBtn
                        },
                    ]}
                >
                    <ScalableIconButton size={30} icon={<MoreActionsIcon />} />
                </ContextualMenu>
            </>
    }, [handleOnExportButtonClicked, handleOnExportAccessesButtonClicked, inventoryDetails, windowResize, locationID, inventoryID,
        handleClickCloseInventoryBtn, handleClickCreateItemBtn, handleClickImportBtn, openPopup,
        handleClickUpdateItemsBtn, handleClickActivateInventoryBtn, totalItems, getInventoryByIDCall.isLoading, inventoryDetails?.status, hasPermission, isLoadingPermissions, hasManagerPermission, isLoadingManagerPermission]);


    const renderStatsCards = useMemo(() => {
        if (windowResize > 1200)
            return <div className="status-cards"><InventoryStatsSection ref={statsSectionRef} locationId={`${locationID}`} inventoryId={inventoryID} /></div>;
    }, [windowResize, inventoryID, locationID])



    return (
        <PageLayout
            tabTitle={"Valmet - " + translate("INVENTORY.Inventory")}
            pageTitle={translate("INVENTORY.ITEM.InventoryItemsPlural")}
            className="inventory-items-page"
            showBreadcrumb
            actions={renderActionButtons}>

            {uploadFileCall.isLoading || editInventoryItemsByFileCallIsLoading || validateItemIsLoading || closeItemIsLoading || putInventoryStateCall.isLoading ? <FullScreenLoader /> : null}

            <div className="storage-general-info">
                <CardContainer className="storage-data">
                    <div className="storage-details-header">
                        {renderStorageDetailsHeader}
                    </div>
                    <Spacer mode="vertical" px="16" />
                    {renderCardContainerInfo}
                </CardContainer>
                {renderStatsCards}
            </div>


            <Spacer mode="vertical" px="20" />
            <div className="subtitle">{translate("INVENTORY.STORAGE.InventoryItems")}</div>
            <Spacer mode="vertical" px="12" />
            <div className="command-bar">
                {renderFilters}
                {isLoadingImport ? <ImportLoader text={translate("INVENTORY.ITEM.ImportLoading")} /> : null}
            </div>
            <Spacer mode="vertical" px="12" />

            <ResponsiveDataTable
                orderColumn={orderColumn}
                onOrderChanged={(newOrder) => { setOrderColumn(newOrder); setCurrentPage(0) }}
                className="items-table"
                columnDefinitions={columns}
                items={inventoryItems ?? []}
                totalitems={totalItems}
                paginationOptions={{ itemsPerPage: itemsPerPage ?? 0, itemsPerPageOptions: [10, 20, 30, 40, 50] }}
                currentpage={currentPage}
                isLoading={inventoryItemsCallIsLoading}
                onClickRow={(item) => navigate(`${item.id}`)}
                onPageAndItemsChanged={onPageAndItemsChanged}
            ></ResponsiveDataTable>
        </PageLayout >
    );
}
